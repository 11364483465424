const ImageManagerConsorcioImovel = {
  banners: {
    BannerConsorcioImovel:
      "https://storage.googleapis.com/primesecure/imovel2.png",
  },
  Logos: {
    logoPorto: "https://storage.googleapis.com/primesecure/logo-porto.webp",
  },
  Investment: {
    bgImage: "https://storage.googleapis.com/primesecure/apartamento2.png",
  },
};
export default ImageManagerConsorcioImovel;
