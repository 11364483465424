//Banner
//import BannerSeguroCelular from "./img/banner-seguro-celular.webp";
//import LogoPorto from "./img/logo-porto.png";

const imageManagerSeguroCelular = {
  banners: {
    BannerSeguroCelular:
      "https://storage.googleapis.com/primesecure/banner-seguro-celular.webp",
  },
  logos: {
    LogoPorto: "https://storage.googleapis.com/primesecure/logo-porto.webp",
  },
  marcasCelulares: {
    imgMotorola: "https://storage.googleapis.com/primesecure/motorola.webp",
    imgSamsumg: "https://storage.googleapis.com/primesecure/samsumg.webp",
    imgApple: "https://storage.googleapis.com/primesecure/apple.webp",
    imgXiaomi: "https://storage.googleapis.com/primesecure/xiaomi.webp",
    outrasMarcas:
      "https://storage.googleapis.com/primesecure/outras-marcas.webp",
  },
  beneficios: {
    celularCrash:
      "https://storage.googleapis.com/primesecure/celular-crash.webp",
    celularGlobo:
      "https://storage.googleapis.com/primesecure/celular-globo.webp",
    portoPlus: "https://storage.googleapis.com/primesecure/porto-plus.webp",
    Cards: "https://storage.googleapis.com/primesecure/cards.webp",
    celularPin: "https://storage.googleapis.com/primesecure/celular-pin.webp",
    Calendar: "https://storage.googleapis.com/primesecure/calendar.webp",
  },
  promo: {
    outubroRosa: "https://storage.googleapis.com/primesecure/Outubro.png",
    outubroAzul: "https://storage.googleapis.com/primesecure/novembro-azul.png",
    blackFriday:
      "https://storage.googleapis.com/primesecure/black-friday_160-x-80-px_.webp",
    natal: "https://storage.googleapis.com/primesecure/Natal.png",
    anoNovo: "https://storage.googleapis.com/primesecure/boas-festas.png",
  },
  Utils: {
    iconHand:
      "https://storage.googleapis.com/primesecure/hand-shake-svgrepo-com.svg",
  },
};

export default imageManagerSeguroCelular;
