const ImageManagerConsorcioAuto = {
  banners: {
    BannerConsorcioAuto: "https://storage.googleapis.com/primesecure/auto.png",
  },
  Logos: {
    logoPorto: "https://storage.googleapis.com/primesecure/logo-porto.webp",
  },
  Investment: {
    bgImage: "https://storage.googleapis.com/primesecure/auto2.png",
  },
};
export default ImageManagerConsorcioAuto;
