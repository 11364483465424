//import ImgSobrePrime from "./img/img-sobre-prime.webp";

const imageManagerSobrePrime = {
  sectionImages: {
    ImgSobrePrime:
      "https://storage.googleapis.com/primesecure/img-sobre-prime.webp",
  },
};

export default imageManagerSobrePrime;
